<template>
  <div>
    <div class="flex justify-between py-2">
      <a-button
          v-if="is_operator"
          type="primary"
          class="default-button mr-1 base_text flex justify-center items-center"
          size="small"
          @click="add_request">
        <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />
        <div class="ml-2">
          {{ $t('add_request_text') }}
        </div>

      </a-button>
    </div>
    <a-collapse accordion class="mb-2">
      <a-collapse-panel key="1" :header="$t('filters')">
        <FormSelect :formItems="filters" ref="refFormCreateRequest" :cols="1" class="mb-2" updated_event_bus="updated_customer_request"/>
      </a-collapse-panel>
    </a-collapse>
    <card>
      <AgGridTable
          ref="refAgGridTableCreateRequest"
          updated_event_bus="updated_customer_request"
          @open_drawer="open_drawer_request"
          :column_defs="column_defs_create_request"
          :end_point="'organizations/customer/'"
          name_event_bus="customer_request_api_event_bus"/>
    </card>
    <client-and-request ref="refClientAndRequest" :title="$t('add_request_text')" @after_create="after_create"/>
    <RequestShowDrawer ref="refRequestShowDrawer" @go_client="go_client"/>
    <client-show-drawer ref="refClientShowDrawer" @open_drawer_request="open_drawer_request"/>
  </div>
</template>

<script>
import FormAndAgGrid from "@/components/Forms/FormAddClient/FormAndAgGrid";
import SelectView from "@/pages/CustomerRequest/SelectView";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import Card from "@/crm_components/card/Card";
import ColumnDefsCreateRequest from "@/pages/AddClient/RequestMixins/ColumnDefsCreateRequest";
import ClientAndRequest from "@/pages/AddClient/ClientAndRequest/ClientAndRequest";
import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import RequestFilterMixin from "@/pages/AddClient/RequestMixins/RequestFilterMixin";
import ClientShowDrawer from "@/pages/AddClient/Client/ClientShowDrawer";
import OpenDrawerClientMixin from "@/pages/AddClient/ClientMixins/OpenDrawerClientMixin";
import RoleMixin from "@/Mixins/RoleMixin";
export default {
  name: "CustomerRequest",
  components: {FormAndAgGrid, AgGridTable, Card, ClientAndRequest, RequestShowDrawer, FormSelect, ClientShowDrawer, },

  props:{
    show_form:{
      type:Boolean,
      default: true
    },
    ag_grid_height: {
      type: String,
      default: '450px'
    },
  },
  mixins:[
    ColumnDefsCreateRequest,
    OpenDrawerRequestMixin,
      DeleteMixin,
    RequestFilterMixin,
    OpenDrawerClientMixin,
    RoleMixin,
  ],
  data(){
    return {
    }
  },
  methods:{
    add_request(){
      this.$refs.refClientAndRequest.visible = true
      this.$refs.refClientAndRequest.add_request = true
    },
    delete_request(){
      this.delete_method('customer_request_api_event_bus', 'organizations/customer/', this.data.data)
    },
    after_create(val){
      let clone = Object.assign({}, val.data.customer_request)
      clone['client'] = val.data
      this.$refs.refClientAndRequest.visible = false
      this.$refs.refAgGridTableCreateRequest.updateMessage(clone)
      console.log('clone', clone)
      this.open_drawer_request({data: clone})
      // this.$refs.refClientAndRequest.visible = false
      // this.$refs.refRequestShowDrawer.data
    },
    go_client(val){
      this.open_drawer({data:val})
      console.log('val', val)
    }
  },
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.default-button{
  width: 200px;
  border-radius: $border-radius-base;
}
.save-button{

}
</style>
